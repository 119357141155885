import * as React from 'react';
import { CellParams } from '@material-ui/x-grid';

interface NormalNumberProps {
    value: number;
}

const NormalNumber = React.memo(function Pnl(props: NormalNumberProps) {
    const { value } = props;

    return (
        <>
            {value === 2 ? "CLOSED" : value === 4 ? "FLOATING" : "OPEN" }
        </>
    );
});

export function renderCombDoor(params: CellParams) {
    return <NormalNumber value={params.value as any} />;
}
