import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    selectDeviceMeasureHistoryRows,
    selectDeviceCurrentMeasures,
    selectDeviceAlertsHistoryRows,
    selectDeviceCurrentAlertsRows,
    selectSelectedDevice,
    selectSelectedSite,
    selectNavbarSelectionPath,
    selectDeviceDetailsPanelOpen,
    setDeviceDetailsPanelOpen,
    setDeviceMeasureHistoryRows,
    setDeviceCurrentMeasures,
    setDeviceAlertsHistoryRows,
    setDeviceCurrentAlertsRows,
    selectDeviceConfig,
    selectDeviceEditedConfig,
    setDeviceConfig,
    setDeviceEditedConfig,
    selectPermissions,
    selectUsername,
    setIsLoading,
    selectTriggerDeviceDetailsDataRefresh,
    setTriggerDeviceDetailsDataRefresh,
    setTriggerDeviceDataRefresh,
    setTriggerAlertsDataRefresh,
    setSelectedDevice,
    setTriggerDataRefresh,
    selectSelectedSiteName
} from '../redux/reducers/dashboardSlice';
import { GetDeviceDetails, UpdateDeviceConfiguration, UserTakesAlerts, GetDefaultConfiguration, DeleteDevice, ResetBattery, GetSiteAnalytics, GetSiteDetails } from "../BkConnect"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SettingsIcon from '@material-ui/icons/Settings';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SaveIcon from '@material-ui/icons/Save';
import Slide from '@material-ui/core/Slide';
import GaugeChart from 'react-advanced-gauge-chart'
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { grey, orange, green, red, blue } from '@material-ui/core/colors';
import { Breadcrumbs, Button, FormControlLabel, Link, Switch } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import {
    renderDistance,
    renderPressure,
    renderTemperature,
    renderUMTS,
    renderHumidity,
    renderBattery,
    renderVBatt,
    renderStatus,
    renderSeverity,
    renderSensor,
    RenderAvatar,
    renderDateTime,
    FillProfilePicCollection,
    renderMultipleSensor,
    getDeviceList,
    renderAnalogsWithValue,
    renderDone,
    renderVerticality,
    renderCombDoor,
    renderMove,
    RenderDistanceValue,
    RenderUMTS,
    renderType
} from './renderer';
// import { JsonEditor as Editor } from 'jsoneditor-react';
// import Ajv from 'ajv';
import Form from "@rjsf/material-ui";
import { cloneDeep, isEqual, startCase } from "lodash"
import 'jsoneditor-react/es/editor.min.css';
import "../Components/MyDeviceDetailPanel.css"
import clsx from 'clsx';
import getRenderPropsFromDeviceType from "./renderer/deviceTypeRenderUtility";
import ObjectFieldTemplate from "./renderer/ObjectFieldTemplate";
import { configSchema } from "./renderer/configSchema";
import ArrayFieldTemplate from "./renderer/ArrayFieldTemplate";
import { BatteryGauge } from "./BatteryGauge";
import getRemainingDays from "./renderer/getRemainingDays";
import { GetRealTimeStamp } from "../utils/date_utils";
import CountUp from 'react-countup';
import { Loop, MeetingRoom, NfcOutlined, SettingsEthernet } from "@material-ui/icons";
import { convertDeviceConfigToHumanReadable, convertDeviceConfigToSystemReadable, convertToHumanReadable, convertToHumanReadable_analogic, getGaugeArcs, getHeartbeatPeriodList, getPercentValue, getSamplePeriodList } from "../utils/measures_converter";
import store from '../redux/store'
import { checkThresholdsIntegrity } from '../utils/measures_converter';
import NFC from "../utils/NFC";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box component={"div"} height="88vh" style={{ overflowY: 'scroll', padding: '15px' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

function customCheckbox(theme) {
    return {
        '& .MuiCheckbox-root svg': {
            width: 16,
            height: 16,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.type === 'light' ? '#d9d9d9' : 'rgb(150, 150, 150)'
                }`,
            borderRadius: 2,
        },
        '& .MuiCheckbox-root svg path': {
            display: 'none',
        },
        '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
            backgroundColor: '#1890ff',
            borderColor: '#1890ff',
        },
        '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
            position: 'absolute',
            display: 'table',
            border: '2px solid #fff',
            borderTop: 0,
            borderLeft: 0,
            transform: 'rotate(45deg) translate(-50%,-50%)',
            opacity: 1,
            transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
            content: '""',
            top: '50%',
            left: '39%',
            width: 5.71428571,
            height: 9.14285714,
        },
        '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
            width: 8,
            height: 8,
            backgroundColor: '#1890ff',
            transform: 'none',
            top: '39%',
            border: 0,
        },
    };
}

function CustomPagination(props) {
    const { paginationProps } = props;

    return (
        <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={paginationProps.page}
            count={paginationProps.pageCount}
            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
            onChange={(event, value) => paginationProps.setPage(value)}
        />
    );
}

CustomPagination.propTypes = {
    /**
     * The object containing all pagination details in [[PaginationProps]].
     */
    paginationProps: PropTypes.shape({
        page: PropTypes.number.isRequired,
        pageCount: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        rowCount: PropTypes.number.isRequired,
        setPage: PropTypes.func.isRequired,
        setPageSize: PropTypes.func.isRequired,
    }).isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        border: 0,
        color:
            theme.palette.type === 'light'
                ? 'rgba(0,0,0,.85)'
                : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.type === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
            borderRight: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-cell': {
            color:
                theme.palette.type === 'light'
                    ? 'rgba(0,0,0,.85)'
                    : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },
        ...customCheckbox(theme),
        '& .MuiDataGrid-overlayContent': {
            flexDirection: 'column',
            alignItems: 'center',
        },
        '& .ant-empty-img-1': {
            fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
            fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
        },
    },
    label: {
        marginTop: theme.spacing(1),
    },
    appBar: {
        position: 'relative',
        height: '8vh',
        minHeight: '8ch'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: grey.A400
    },
    dualPaperTop: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: grey.A400
    },
    dualPaperBottom: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: grey.A400
    },
    dualPaperTop_big: {
        marginBottom: theme.spacing(2),
        padding: '13px',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: grey.A400
    },
    dualPaperBottom_big: {
        marginTop: theme.spacing(2),
        padding: '13px',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: grey.A400
    },
    gridRoot: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    btn: {
        margin: theme.spacing(1),
    },
    gridField: {
        padding: theme.spacing(1),
    },
    formCtrl: {
        textAlign: "center",
        '& .MuiInputBase-formControl': {
            height: '70px',
        },
        height: '70px'
    },
    bkgrnd: {
        backgroundColor: '#212121'
    },
    counter: {
        fontWeight: 700,
        fontSize: '21px'
    },
    red: {
        color: red[800]
    }
}));

function CustomNoRowsOverlay() {
    const classes = useStyles();

    return (
        <GridOverlay className={classes.root}>
            <svg
                width="120"
                height="100"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <div className={classes.label}>No Rows <EmojiEmotionsIcon /></div>
        </GridOverlay>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const sortModel_measures = [
    {
        field: 'ts',
        sort: 'desc',
    },
];

function GetAnalogValue(params) {
    const conf = store ? store.getState().dashboard.deviceConfig : undefined // workaround

    if (conf) {
        return JSON.stringify({
            value: Number(params.value),
            sensor_name: params.field,
            min: Number(conf[params.field].ref_min),
            max: Number(conf[params.field].ref_max),
            unit: conf[params.field].ref_uom
        })
    }
    return JSON.stringify({
        value: Number(params.value),
        sensor_name: params.field,
        min: 0,
        max: 1,
        unit: "N/a"
    })
}

const measures_columns = [
    {
        field: 'msg_type',
        headerName: 'T',
        type: 'number',
        width: 50,
        renderCell: renderType
    },
    {
        field: 'id',
        headerName: 'ID',
        type: 'number',
        width: 5,
        hide: true
    },
    {
        field: 'ts',
        headerName: 'Time',
        type: 'dateTime',
        width: 150,
        renderCell: renderDateTime,
    },
    {
        field: 'battery_level',
        headerName: 'Battery Level',
        type: 'number',
        width: 120,
        renderCell: renderVBatt
    },
    {
        field: 'residual_battery',
        headerName: "Residual Batt.",
        type: 'string',
        width: 120,
        renderCell: renderBattery
    },
    {
        field: 'antenna_signal',
        headerName: 'Antenna',
        type: 'number',
        width: 110,
        renderCell: renderUMTS
    },
    {
        field: 'temperature',
        headerName: 'Temp.',
        type: 'number',
        width: 110,
        renderCell: renderTemperature
    },
    {
        field: 'pres_atm',
        headerName: 'Ext. Pressure',
        type: 'number',
        width: 120,
        renderCell: renderPressure
    },
    {
        field: 'humidity',
        headerName: 'Humidity',
        type: 'number',
        width: 110,
        renderCell: renderHumidity
    },
    {
        field: 'verticality',
        headerName: 'Inclination',
        type: 'number',
        width: 100,
        renderCell: renderVerticality
    },
    {
        field: 'moved',
        type: 'number',
        headerName: 'Moved',
        width: 80,
        renderCell: renderMove
    },
    {
        field: 'closed_door',
        type: 'number',
        headerName: 'Door Closed',
        width: 120,
        renderCell: renderDone
    },
    {
        field: 'comb_door',
        type: 'number',
        headerName: 'Combo O/C',
        width: 110,
        renderCell: renderCombDoor
    },
    {
        field: 'distance',
        type: 'number',
        headerName: 'Distance',
        width: 100,
        renderCell: renderDistance
    },
    {
        field: 'open_close_cycles',
        type: 'number',
        headerName: 'Open/close Cycles',
        width: 150,
    },
    {
        field: 'analogic_sensor_1',
        type: 'number',
        headerName: 'A1',
        width: 110,
        valueGetter: GetAnalogValue,
        renderCell: renderAnalogsWithValue,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
            cellParams1.value - cellParams2.value
    },
    {
        field: 'analogic_sensor_2',
        type: 'number',
        headerName: 'A2',
        width: 110,
        valueGetter: GetAnalogValue,
        renderCell: renderAnalogsWithValue,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
            cellParams1.value - cellParams2.value
    },
    {
        field: 'analogic_sensor_3',
        type: 'number',
        headerName: 'A3',
        width: 110,
        valueGetter: GetAnalogValue,
        renderCell: renderAnalogsWithValue,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
            cellParams1.value - cellParams2.value
    },
    {
        field: 'analogic_sensor_4',
        type: 'number',
        headerName: 'A4',
        width: 110,
        valueGetter: GetAnalogValue,
        renderCell: renderAnalogsWithValue,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
            cellParams1.value - cellParams2.value
    },
    {
        field: 'digital_sensor_1',
        type: 'number',
        headerName: 'D1',
        width: 55,
        renderCell: renderDone
    },
    {
        field: 'digital_sensor_2',
        type: 'number',
        headerName: 'D2',
        width: 55,
        renderCell: renderDone
    },
    {
        field: 'digital_sensor_3',
        type: 'number',
        headerName: 'D3',
        width: 55,
        renderCell: renderDone
    },
    {
        field: 'digital_sensor_4',
        type: 'number',
        headerName: 'D4',
        width: 55,
        renderCell: renderDone
    },
    {
        field: 'retry_counter',
        type: 'number',
        headerName: 'Retry Counter',
        width: 130,
    }
    // {
    //     field: 'digital_sensor_5',
    //     type: 'number',
    //     headerName: 'D5',
    //     width: 55,
    //     renderCell: renderDone
    // },
    // {
    //     field: 'digital_sensor_6',
    //     type: 'number',
    //     headerName: 'D6',
    //     width: 55,
    //     renderCell: renderDone
    // },
    // {
    //     field: 'digital_sensor_7',
    //     type: 'number',
    //     headerName: 'D7',
    //     width: 55,
    //     renderCell: renderDone
    // },
    // {
    //     field: 'digital_sensor_8',
    //     type: 'number',
    //     headerName: 'D8',
    //     width: 55,
    //     renderCell: renderDone
    // },
]

function getStatus(params) {
    if (params.getValue('is_taken') && !params.getValue('is_cleared')) return "AlertTaken"
    else if (!params.getValue('is_taken') && !params.getValue('is_cleared')) return "NewAlert"
    else if (params.getValue('is_cleared')) return "AlertCleared"
}

function getMultipleValueFormatted(params) {
    let value = 'N/a'
    if (params.getValue('value_at_raise') !== null) {
        value = params.getValue('value_at_raise').value
    }
    let sensor = params.getValue('sensor')
    if (params.getValue('sensor').includes('analogic')) {
        const clonedParams = cloneDeep(params)
        clonedParams.field = params.getValue('sensor')
        clonedParams.value = params.value.value
        sensor = 'analogic_sensor_value'
        value = GetAnalogValue(clonedParams)
    }
    return JSON.stringify({
        sensor,
        value
    })
}

function getMultipleValueForSort(params) {
    let value = -9999
    if (params.getValue('value_at_raise') !== null) {
        value = params.getValue('value_at_raise').value
    }
    return value
}

const sortModel_alerts = [
    {
        field: 'ts_raise',
        sort: 'desc',
    },
];

const alerts_columns = [
    {
        field: 'alert_id',
        headerName: 'Alarm ID',
        type: 'number',
        width: 5,
        hide: true
    },
    {
        field: 'device_serial',
        headerName: 'Device ID',
        type: 'number',
        width: 50,
        hide: true
    },
    {
        field: 'severity',
        headerName: 'Severity',
        type: 'string',
        width: 110,
        renderCell: renderSeverity
    },
    {
        field: 'ts_raise',
        headerName: 'Raise Time',
        type: 'dateTime',
        width: 170,
        renderCell: renderDateTime,
    },
    {
        field: 'sensor',
        headerName: 'Sensor',
        type: 'string',
        width: 250,
        renderCell: renderSensor,
    },
    {
        field: 'value_at_raise',
        headerName: 'Value',
        type: 'number',
        width: 150,
        valueGetter: getMultipleValueFormatted,
        renderCell: renderMultipleSensor,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
            getMultipleValueForSort(cellParams1) - getMultipleValueForSort(cellParams2)
    },
    {
        field: 'status',
        headerName: "Status",
        width: 110,
        renderCell: renderStatus,
        valueGetter: getStatus,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
            getStatus(cellParams1).localeCompare(getStatus(cellParams2))
    },
    {
        field: 'is_taken',
        type: 'string',
        headerName: 'Taken',
        width: 70,
        hide: true
    },
    {
        field: 'taken_by',
        headerName: 'Taken by',
        type: 'string',
        width: 180,
        renderCell: RenderAvatar,
    },
    {
        field: 'ts_taken',
        headerName: 'Taken Time',
        type: 'dateTime',
        width: 170,
        renderCell: renderDateTime,
    },
    {
        field: 'is_cleared',
        type: 'string',
        headerName: 'Cleared',
        width: 85,
        hide: true
    },
    {
        field: 'ts_cleared',
        headerName: 'Cleared Time',
        type: 'dateTime',
        width: 170,
        renderCell: renderDateTime,
    },
]

const alerts_history_columns = [
    {
        field: 'alert_id',
        headerName: 'Alarm ID',
        type: 'number',
        width: 5,
        hide: true
    },
    {
        field: 'device_serial',
        headerName: 'Device ID',
        type: 'number',
        width: 165,
        hide: true
    },
    {
        field: 'severity',
        headerName: 'Severity',
        type: 'string',
        width: 110,
        renderCell: renderSeverity
    },
    {
        field: 'ts_raise',
        headerName: 'Raise Time',
        type: 'dateTime',
        width: 170,
        renderCell: renderDateTime,
    },
    {
        field: 'sensor',
        headerName: 'Sensor',
        type: 'string',
        width: 250,
        renderCell: renderSensor,
    },
    {
        field: 'value_at_raise',
        headerName: 'Value',
        type: 'number',
        width: 100,
        valueGetter: getMultipleValueFormatted,
        renderCell: renderMultipleSensor,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
            getMultipleValueForSort(cellParams1) - getMultipleValueForSort(cellParams2)
    },
    {
        field: 'status',
        headerName: "Status",
        width: 110,
        renderCell: renderStatus,
        valueGetter: getStatus,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
            getStatus(cellParams1).localeCompare(getStatus(cellParams2))
    },
    {
        field: 'is_taken',
        type: 'string',
        headerName: 'Taken',
        width: 70,
        hide: true
    },
    {
        field: 'taken_by',
        headerName: 'Taken by',
        type: 'string',
        width: 150,
        renderCell: RenderAvatar,
    },
    {
        field: 'ts_taken',
        headerName: 'Taken Time',
        type: 'dateTime',
        width: 170,
        renderCell: renderDateTime,
    },
    {
        field: 'is_cleared',
        type: 'string',
        headerName: 'Cleared',
        width: 85,
        hide: true
    },
    {
        field: 'ts_cleared',
        headerName: 'Cleared Time',
        type: 'dateTime',
        width: 170,
        renderCell: renderDateTime,
    },
    {
        field: 'closed_by',
        headerName: 'Closed by',
        type: 'number',
        width: 150,
        renderCell: RenderAvatar,
    },
]


function useData(rowsCollection, type = 'measures') {
    const [data, setData] = useState({ columns: [], rows: [] });
    const headerAlignment = 'center'

    useEffect(() => {
        const rows = [];
        let i = 0
        for (let _row in rowsCollection) {
            const row = {
                id: i,
            }
            i++
            for (let _col in rowsCollection[_row]) {
                row[_col] = rowsCollection[_row][_col];
            }
            rows.push(row);
        }

        const columns = [{ field: 'id', hide: true }];

        switch (type) {
            case 'alerts':
                for (let _col in alerts_columns) {
                    if (typeof alerts_columns[_col].renderCell !== 'undefined' && typeof alerts_columns[_col].valueGetter !== 'undefined')
                        columns.push({
                            field: alerts_columns[_col].field,
                            headerName: alerts_columns[_col].headerName,
                            type: alerts_columns[_col].type,
                            width: alerts_columns[_col].width,
                            renderCell: alerts_columns[_col].renderCell,
                            valueGetter: alerts_columns[_col].valueGetter,
                            sortComparator: alerts_columns[_col].sortComparator,
                            headerAlign: headerAlignment,
                            align: headerAlignment,
                        });
                    else if (typeof alerts_columns[_col].renderCell !== 'undefined' && typeof alerts_columns[_col].valueGetter == 'undefined')
                        columns.push({
                            field: alerts_columns[_col].field,
                            headerName: alerts_columns[_col].headerName,
                            type: alerts_columns[_col].type,
                            width: alerts_columns[_col].width,
                            renderCell: alerts_columns[_col].renderCell,
                            headerAlign: headerAlignment,
                            align: headerAlignment,
                        });
                    else if (typeof alerts_columns[_col].hide !== 'undefined')
                        columns.push({
                            field: alerts_columns[_col].field,
                            headerName: alerts_columns[_col].headerName,
                            type: alerts_columns[_col].type,
                            width: alerts_columns[_col].width,
                            hide: alerts_columns[_col].hide,
                            headerAlign: headerAlignment,
                            align: headerAlignment,
                        });
                    else
                        columns.push({
                            field: alerts_columns[_col].field,
                            headerName: alerts_columns[_col].headerName,
                            width: alerts_columns[_col].width,
                            type: alerts_columns[_col].type,
                            headerAlign: headerAlignment,
                            align: headerAlignment,
                        });
                }
                break
            case 'alerts_history':
                for (let _col in alerts_history_columns) {
                    if (typeof alerts_history_columns[_col].renderCell !== 'undefined' && typeof alerts_history_columns[_col].valueGetter !== 'undefined')
                        columns.push({
                            field: alerts_history_columns[_col].field,
                            headerName: alerts_history_columns[_col].headerName,
                            type: alerts_history_columns[_col].type,
                            width: alerts_history_columns[_col].width,
                            renderCell: alerts_history_columns[_col].renderCell,
                            valueGetter: alerts_history_columns[_col].valueGetter,
                            sortComparator: alerts_history_columns[_col].sortComparator,
                            headerAlign: headerAlignment,
                            align: headerAlignment,
                        });
                    else if (typeof alerts_history_columns[_col].renderCell !== 'undefined' && typeof alerts_history_columns[_col].valueGetter == 'undefined')
                        columns.push({
                            field: alerts_history_columns[_col].field,
                            headerName: alerts_history_columns[_col].headerName,
                            type: alerts_history_columns[_col].type,
                            width: alerts_history_columns[_col].width,
                            renderCell: alerts_history_columns[_col].renderCell,
                            headerAlign: headerAlignment,
                            align: headerAlignment,
                        });
                    else if (typeof alerts_history_columns[_col].hide !== 'undefined')
                        columns.push({
                            field: alerts_history_columns[_col].field,
                            headerName: alerts_history_columns[_col].headerName,
                            type: alerts_history_columns[_col].type,
                            width: alerts_history_columns[_col].width,
                            hide: alerts_history_columns[_col].hide,
                            headerAlign: headerAlignment,
                            align: headerAlignment,
                        });
                    else
                        columns.push({
                            field: alerts_history_columns[_col].field,
                            headerName: alerts_history_columns[_col].headerName,
                            width: alerts_history_columns[_col].width,
                            type: alerts_history_columns[_col].type,
                            headerAlign: headerAlignment,
                            align: headerAlignment,
                        });
                }
                break
            case 'measures':
                for (let _col in measures_columns) {
                    if (typeof measures_columns[_col].renderCell !== 'undefined' && typeof measures_columns[_col].valueGetter !== 'undefined')
                        columns.push({
                            field: measures_columns[_col].field,
                            headerName: measures_columns[_col].headerName,
                            type: measures_columns[_col].type,
                            width: measures_columns[_col].width,
                            renderCell: measures_columns[_col].renderCell,
                            valueGetter: measures_columns[_col].valueGetter,
                            sortComparator: measures_columns[_col].sortComparator,
                            headerAlign: headerAlignment,
                            align: headerAlignment,
                        });
                    else if (typeof measures_columns[_col].renderCell !== 'undefined' && typeof measures_columns[_col].valueGetter == 'undefined')
                        columns.push({
                            field: measures_columns[_col].field,
                            headerName: measures_columns[_col].headerName,
                            type: measures_columns[_col].type,
                            width: measures_columns[_col].width,
                            renderCell: measures_columns[_col].renderCell,
                            headerAlign: headerAlignment,
                            align: headerAlignment,
                        });
                    else if (typeof measures_columns[_col].hide !== 'undefined')
                        columns.push({
                            field: measures_columns[_col].field,
                            headerName: measures_columns[_col].headerName,
                            type: measures_columns[_col].type,
                            width: measures_columns[_col].width,
                            hide: measures_columns[_col].hide,
                            headerAlign: headerAlignment,
                            align: headerAlignment,
                        });
                    else
                        columns.push({
                            field: measures_columns[_col].field,
                            headerName: measures_columns[_col].headerName,
                            width: measures_columns[_col].width,
                            type: measures_columns[_col].type,
                            headerAlign: headerAlignment,
                            align: headerAlignment,
                        });
                }
                break
            default:
                break
        }

        setData({
            rows,
            columns,
        });
    }, [rowsCollection]); // eslint-disable-line react-hooks/exhaustive-deps

    //console.log("DATI REALI:",data)
    return data;
}

const chartStyle = {
    height: "100%",
}

const error = {
    txtIpAddress: false,
    txtServerPort: false,
    txtDescription: false,
    txtPositioning: false
}

export function MyDeviceDetailPanel(props) {
    const [small, setSmall] = useState(false);
    const openPanel = useSelector(selectDeviceDetailsPanelOpen);
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const username = useSelector(selectUsername);
    const selectedSiteId = useSelector(selectSelectedSite)
    const [selectedSiteName, setSelectedSiteName] = React.useState("_")
    const selectedDeviceId = useSelector(selectSelectedDevice)
    const measuresHistoryRows = useSelector(selectDeviceMeasureHistoryRows)
    const alertsHistoryRows = useSelector(selectDeviceAlertsHistoryRows)
    const currentAlertsRows = useSelector(selectDeviceCurrentAlertsRows)
    const currentMeasures = useSelector(selectDeviceCurrentMeasures)
    const deviceConfig = useSelector(selectDeviceConfig)
    const permissions = useSelector(selectPermissions)
    const [selectedTab, setSelectedTab] = React.useState(0)
    const editedJson = useSelector(selectDeviceEditedConfig)
    const [isEdited, setIsEdited] = React.useState(false);
    const [isDefaultConfig, setIsDefaultConfig] = React.useState(false);
    const navbarSelection = useSelector(selectNavbarSelectionPath)
    // const ajv = new Ajv({ allErrors: true, verbose: true });
    const classes = useStyles();
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [confirmDialogType, setConfirmDialogType] = React.useState('alerts');
    const [selectedAlertsToTake, setSelectedAlertsToTake] = React.useState([])
    const [selectedAlertsToClose, setSelectedAlertsToClose] = React.useState([])
    const [action, setAction] = React.useState('disabled');
    const triggerDataRefresh = useSelector(selectTriggerDeviceDetailsDataRefresh)

    const [heartBeatPeriod, setHeartBeatPeriod] = React.useState(1440);
    const [samplePeriod, setSamplePeriod] = React.useState(250);
    const [description, setDescription] = React.useState("");
    const [operative_status, setOperative_status] = React.useState("");
    const [positioning, setPositioning] = React.useState("");
    const [text1, setText1] = React.useState("");
    const [text2, setText2] = React.useState("");
    const [serial_number, setSerial_number] = React.useState("");
    const [server_ip_addr, setServer_ip_addr] = React.useState("");
    const [server_port, setServer_port] = React.useState(2);
    const [type, setType] = React.useState("");
    const [selectedType, setSelectedType] = React.useState("");
    const [remainingDays, setRemainingDays] = React.useState("");
    const [noHeartbeat, setNoHeartbeat] = React.useState(false)
    const [activationTs, setActivationTs] = React.useState(0)

    const [gaugeProps, setGaugeProps] = React.useState([])
    const [selectionPath, setSelectionPath] = React.useState([])
    const [navbarSelectionPath, setNavbarSelectionPath] = React.useState(<></>)
    const Writer = new NFC();

    useEffect(() => {
        let _navbarSelectionPath = selectionPath.map(el => {
            if (selectionPath.indexOf(el) === selectionPath.length - 1) {
                return (
                    <Typography key={el} color="textPrimary">{startCase(el.name)}</Typography>
                )
            } else {
                return (
                    <Link key={el} color="inherit" href="#">{startCase(el.name)}</Link>
                )
            }
        })
        setNavbarSelectionPath(_navbarSelectionPath)
        const mediaQuery = window.matchMedia('(min-width: 880px)');
        //console.log(mediaQuery)
        if (mediaQuery.matches) {
            setSmall(false)
        } else {
            setSmall(true)
        }
        mediaQuery.addEventListener("change", (mq) => {
            if (mq.matches) {
                setSmall(false)
            } else {
                setSmall(true)
            }
        });
    }, [selectionPath])

    async function getNavbarArrayFromPath(_path, userSiteName) {
        let out = []
        const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g
        const tempSitePath = userSiteName
            .trim()
            .replace(regex, ' ')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join('')

        for (let el of _path) {
            out.push({
                name: el.trim()
                    .replace(regex, ' ')
                    .split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join('')
            })
        }
        return out
    }

    async function updateData(serialNumber) {
        dispatch(setTriggerDeviceDetailsDataRefresh(false))
        dispatch(setIsLoading(true))
        let _tab = await GetDeviceDetails(serialNumber, selectedSiteId)
        console.log(_tab)
        let current_alerts = _tab.device_details.currentAlerts
        let alerts_history = _tab.device_details.historicAlerts
        let measure_history = _tab.device_details.historicMeasures
        let device = _tab.device_details.device[0]

        setNoHeartbeat(false)

        if (device !== undefined) {
            dispatch(setDeviceConfig(convertDeviceConfigToHumanReadable(device.config)))
            dispatch(setDeviceEditedConfig(convertDeviceConfigToHumanReadable(device.config)))
            setDescription((device.description === null ? '' : device.description))
            setOperative_status(device.operative_status)
            setPositioning((device.positioning === null ? '' : device.positioning))
            setText1((device.text1 === null ? '' : device.text1))
            setText2((device.text2 === null ? '' : device.text2))
            setSerial_number(device.serial_number)
            setServer_ip_addr(device.server_ip_addr)
            setServer_port(device.server_port)
            setType(device.type)
            setHeartBeatPeriod(device.heartbeat_period)
            setSamplePeriod(device.sample_period)
            setActivationTs(device.activation_ts)
            const resp = await GetSiteDetails(device.site_id)
            let navArr = resp.site_details.path.split('.')
            let navbarWithFunctions = await getNavbarArrayFromPath(navArr, resp.site_details.siteName)
            setSelectionPath(navbarWithFunctions)


            // Loads Measures and Measures history
            if (_tab.length === 0) return
            let _rows = []
            measure_history.forEach((e) => {
                let thisRow = {
                    id: e.id,
                    ts: e.ts,
                    battery_level: e.battery_level,
                    antenna_signal: e.antenna_signal,
                    residual_battery: e.residual_battery,
                    temperature: e.temperature,
                    pres_atm: e.pres_atm,
                    humidity: e.humidity,
                    moved: e.moved,
                    verticality: e.verticality,
                    closed_door: e.closed_door,
                    comb_door: e.comb_door,
                    distance: e.distance,
                    open_close_cycles: e.open_close_cycles,
                    analogic_sensor_1: e.analogic_sensor_1,
                    analogic_sensor_2: e.analogic_sensor_2,
                    analogic_sensor_3: e.analogic_sensor_3,
                    analogic_sensor_4: e.analogic_sensor_4,
                    digital_sensor_1: e.digital_sensor_1,
                    digital_sensor_2: e.digital_sensor_2,
                    digital_sensor_3: e.digital_sensor_3,
                    digital_sensor_4: e.digital_sensor_4,
                    retry_counter: e.retry_counter,
                    network_no_heartbeat: e.network_no_heartbeat,
                    network_malformed: e.network_malformed,
                    msg_type: e.msg_type
                }
                _rows.push(thisRow)
            })
            //console.log(_rows)
            dispatch(setDeviceMeasureHistoryRows(_rows))
            if (_tab.device_details.currentMeasure.length > 0) {
                dispatch(setDeviceCurrentMeasures(_tab.device_details.currentMeasure[0]))
                if (device.activation_ts) {
                    setRemainingDays(getRemainingDays(device.activation_ts, _tab.device_details.currentMeasure[0].ts, _tab.device_details.currentMeasure[0].residual_battery).remainingDays)
                }
            } else {
                dispatch(setDeviceCurrentMeasures({
                    battery_level: 0,
                    residual_battery: 0,
                    antenna_signal: 0,
                    temperature: 0,
                    pres_atm: 0,
                    humidity: 0,
                    verticality: 0,
                }))
            }

            // Loads Alerts history data
            _rows = []
            alerts_history.forEach((e) => {
                let thisRow = {
                    alert_id: Number(e.id),
                    device_serial: e.device_serial,
                    sensor: e.sensor,
                    severity: e.severity,
                    value_at_raise: e.value_at_raise,
                    ts_raise: e.ts_raise,
                    is_taken: e.is_taken,
                    taken_by: { name: e.taken_by, color: orange[500] },
                    ts_taken: e.ts_taken,
                    is_cleared: e.is_cleared,
                    closed_by: { name: e.closed_by, color: green[500] },
                    ts_cleared: e.ts_cleared,
                }
                _rows.push(thisRow)
            })
            for (let _al of _rows) {
                if (_al.taken_by) await FillProfilePicCollection(_al.taken_by.name)
                if (_al.closed_by) await FillProfilePicCollection(_al.closed_by.name)
            }
            //console.log(_rows)
            dispatch(setDeviceAlertsHistoryRows(_rows))

            // Loads Current Alerts data
            _rows = []
            current_alerts.forEach((e) => {
                let thisRow = {
                    alert_id: Number(e.id),
                    device_serial: e.device_serial,
                    sensor: e.sensor,
                    severity: e.severity,
                    value_at_raise: e.value_at_raise,
                    ts_raise: e.ts_raise,
                    is_taken: e.is_taken,
                    taken_by: { name: e.taken_by, color: orange[500] },
                    ts_taken: e.ts_taken,
                    is_cleared: e.is_cleared,
                    ts_cleared: e.ts_cleared,
                }
                _rows.push(thisRow)
                if (e.sensor === 'network_no_heartbeat') {
                    // colora di rosso il font delle misure
                    setNoHeartbeat(true)
                }
            })
            for (let _al of _rows) {
                if (_al.taken_by) await FillProfilePicCollection(_al.taken_by.name)
                if (_al.closed_by) await FillProfilePicCollection(_al.closed_by.name)
            }
            //console.log(_rows)
            dispatch(setDeviceCurrentAlertsRows(_rows))
            setIsEdited(false)
            dispatch(setIsLoading(false))
        }
    }

    const handleChangeTabs = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleChangeDeviceType = (event) => { // event.target.value
        setOpenConfirmDialog(true);
        setConfirmDialogType('device_type')
        setSelectedType(event.target.value)
    };

    const handleChangeOperativeStatus = (event) => { // event.target.value
        setIsEdited(true)
        setOperative_status(event.target.value)
    };

    const handleChangeHeartBeatPeriod = (event) => { // event.target.value
        if (event.target.value >= 5 && event.target.value <= 65535) {
            setHeartBeatPeriod(event.target.value)
            setIsEdited(true)
        }
    };

    const handleChangeSamplePeriod = (event) => { // event.target.value
        if (event.target.value >= 1 && event.target.value <= 250) {
            setSamplePeriod(event.target.value)
            setIsEdited(true)
        }
    };

    const handleChangeConfig = (json) => { // event.target.value
        if (isDefaultConfig) {
            setIsDefaultConfig(false)
        }
        setIsEdited(true)
        dispatch(setDeviceEditedConfig(json.formData))
    };

    function validateIPaddress(ipaddress) {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
            return (true)
        }
        return (false)
    }

    const handleChangeTextInputs = (event) => {
        switch (event.target.id) {
            case "txtIpAddress":
                setServer_ip_addr(event.target.value)
                if (validateIPaddress(event.target.value)) {
                    error[event.target.id] = false
                }
                else {
                    error[event.target.id] = true
                }
                break
            case "txtServerPort":
                setServer_port(event.target.value)
                if (!Number.isNaN(Number(event.target.value))) {
                    error[event.target.id] = false
                }
                else {
                    error[event.target.id] = true
                }
                break
            case "txtDescription":
                setDescription(event.target.value)
                break
            case "txtPositioning":
                setPositioning(event.target.value)
                break
            case "txtText1":
                setText1(event.target.value)
                break
            case "txtText2":
                setText2(event.target.value)
                break
            default:
                break
        }
        setIsEdited(true)
    };

    const updateConfiguration = async () => { // event.target.value
        for (let er in error) {
            if (error[er]) {
                enqueueSnackbar('You have to fill the form as well!', { variant: 'error' });
                return
            }
        }
        dispatch(setIsLoading(true))
        // Send Updated Configuration
        let updatedConfiguration = {
            server_ip_addr: server_ip_addr,
            server_port: server_port,
            deviceType: type,
            heartbeat_period: heartBeatPeriod,
            sample_period: samplePeriod,
            description: description,
            positioning: positioning,
            text1: text1,
            text2: text2,
            operative_status: operative_status,
        }
        const errors = checkThresholdsIntegrity(editedJson);
        if (errors.length) {
            enqueueSnackbar(errors, {
                variant: 'error',
                style: { whiteSpace: 'pre-line' }
            });
            dispatch(setIsLoading(false));
            return;
        }
        if (isDefaultConfig || (!isEqual(editedJson, deviceConfig) && Object.keys(editedJson).length > 0)) {
            updatedConfiguration.config = convertDeviceConfigToSystemReadable(editedJson)
        }
        let res = await UpdateDeviceConfiguration(serial_number, selectedSiteId, updatedConfiguration)
        if (res.accepted) {
            dispatch(setDeviceConfig(convertDeviceConfigToHumanReadable(editedJson)))
            setSelectedTab(0)
            setIsDefaultConfig(false)
            dispatch(setTriggerDeviceDataRefresh('open'))
            dispatch(setDeviceDetailsPanelOpen(false))
            enqueueSnackbar('Configuration Updated!', { variant: 'success' });
        } else {
            enqueueSnackbar('Server Rejected New Configuration!', { variant: 'error' });
        }
        dispatch(setIsLoading(false))
    };

    useEffect(() => {
        if (deviceConfig) {
            console.log("ciao")
            let _gaugeProps = []
            for (let el in deviceConfig) {
                if (deviceConfig[el].active !== 'no') {
                    switch (el) {
                        case 'temperature':
                        case 'pres_atm':
                        case 'humidity':
                        case 'analogic_sensor_1':
                        case 'analogic_sensor_2':
                        case 'analogic_sensor_3':
                        case 'analogic_sensor_4':
                            _gaugeProps[el] = getGaugeArcs(el, deviceConfig)
                            break
                        default:
                            break
                    }
                }
            }
            setGaugeProps(_gaugeProps)
        }
    }, [deviceConfig])

    useEffect(() => {
        if (selectedDeviceId.toString() !== '0') {
            if (triggerDataRefresh) {
                dispatch(setIsLoading(true)) // Enable loading spinner
                updateData(selectedDeviceId)
            }
        }
    }, [triggerDataRefresh, selectedDeviceId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedDeviceId.toString() !== '0') {
            async function stopper() {
                await updateData(selectedDeviceId)
            }
            stopper()
        }
    }, [selectedDeviceId]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleClose = () => {
        if (isEdited) {
            setOpenConfirmDialog(true);
            setConfirmDialogType('discard')
        } else {
            setSelectedTab(0)
            dispatch(setDeviceDetailsPanelOpen(false))
        }
    };

    const handleSelectAlerts = (alerts) => {
        if (alerts.rows.length === 0 && triggerDataRefresh) {
            updateData(selectedDeviceId)
        }
        let alertsToTake = []
        let alertsToClose = []
        for (let alert in alerts.rows) {
            if (alerts.rows[alert].is_taken !== true && alerts.rows[alert].is_cleared !== true) {
                alertsToTake.push(alerts.rows[alert].alert_id)
            } else if (alerts.rows[alert].is_cleared === true) {
                alertsToClose.push(alerts.rows[alert].alert_id)
            }
        }
        if (alertsToTake.length > 0 && alertsToClose.length === 0) {
            setAction('take')
            setSelectedAlertsToTake(alertsToTake)
            setSelectedAlertsToClose([])
        } else if (alertsToTake.length === 0 && alertsToClose.length > 0) {
            setAction('close')
            setSelectedAlertsToClose(alertsToClose)
            setSelectedAlertsToTake([])
        } else if (alertsToTake.length > 0 && alertsToClose.length > 0) {
            setAction('take & close')
            setSelectedAlertsToTake(alertsToTake)
            setSelectedAlertsToClose(alertsToClose)
        } else if (alertsToTake.length === 0 && alertsToClose.length === 0) {
            setAction('disabled')
            setSelectedAlertsToTake([])
            setSelectedAlertsToClose([])
        }
    };

    const handleCloseDialog = async (_action) => {
        switch (_action) {
            case "confirm_alerts":
                switch (action) {
                    case 'take':
                        await updateAlertsOnTheServer(username, selectedAlertsToTake, 'take')
                        break
                    case 'close':
                        await updateAlertsOnTheServer(username, selectedAlertsToClose, 'close')
                        break
                    case 'take & close':
                        await updateAlertsOnTheServer(username, selectedAlertsToTake, 'take')
                        await updateAlertsOnTheServer(username, selectedAlertsToClose, 'close')
                        break
                    default:
                        break
                }
                break
            case "confirm_configuration":
                // TODO: mettere qui controllo correttezza JSON editato?
                updateConfiguration()
                break
            case 'confirm_reset_battery':
                let resBattery = await ResetBattery(selectedDeviceId)
                if (resBattery && resBattery.accepted) {
                    enqueueSnackbar("Divice battery resetted! ", { variant: 'success' })
                    dispatch(setTriggerDeviceDetailsDataRefresh(true))
                } else {
                    enqueueSnackbar("Server rejected this action!", { variant: 'error' })
                }
                break
            case "confirm_delete":
                let _res = await DeleteDevice(selectedDeviceId)
                if (_res && _res.accepted) {
                    enqueueSnackbar("Device deleted! ", { variant: 'success' })
                    dispatch(setDeviceDetailsPanelOpen(false))
                    // go to parent site
                    dispatch(setSelectedDevice("0"))
                    dispatch(setTriggerDeviceDataRefresh('open'))

                    dispatch(setTriggerAlertsDataRefresh(true))
                    // go to parent sites
                    navbarSelection.forEach(e => {
                        setTimeout(() => dispatch(setTriggerDataRefresh(Number(e.siteId))), 200)
                    })
                    //setTimeout(() => dispatch(setSelectedSite(selectedSiteId)), 500)
                } else {
                    enqueueSnackbar("Server rejected this action!", { variant: 'error' })
                }
                break
            case "confirm_device_type":
                let res = await GetDefaultConfiguration(selectedType)
                if (res && res.accepted) {
                    enqueueSnackbar("Default configuration downloaded! ", { variant: 'success' })
                    setType(selectedType)
                    setIsDefaultConfig(true)
                    setIsEdited(true)
                    dispatch(setDeviceEditedConfig(convertDeviceConfigToHumanReadable(res.defaultConfiguration)))
                    dispatch(setDeviceConfig(convertDeviceConfigToHumanReadable(res.defaultConfiguration)))
                } else {
                    enqueueSnackbar("Server rejected this action!", { variant: 'error' })
                }
                break
            case 'confirm_discard':
                setSelectedTab(0)
                dispatch(setDeviceDetailsPanelOpen(false))
                updateData(selectedDeviceId)
                break
            default:
                break
        }
        setOpenConfirmDialog(false);
    };

    async function updateAlertsOnTheServer(_username, _selectedAlerts, _action) {
        let res = await UserTakesAlerts(selectedSiteId, _username, _selectedAlerts, _action)
        if (res) {
            enqueueSnackbar("Updated selected alerts! ", { variant: 'success' })
            updateData(selectedDeviceId)
            setTimeout(() => { dispatch(setTriggerAlertsDataRefresh(true)) }, 200)
        } else {
            enqueueSnackbar("Server rejected this action!", { variant: 'error' })
        }
    }

    const ConfirmDialog = () => {
        React.useEffect(() => {
            if (openConfirmDialog && confirmDialogType === 'nfc') {
                if (Writer.nfcAvailable) {
                    Writer.writeConfigToTag(serial_number).then((res) => {
                        if (res) {
                            enqueueSnackbar(`NFC Tag written. SN: ${res.serialNumber}`, { variant: 'success' })
                        } else {
                            enqueueSnackbar("NFC Tag not written!", { variant: 'error' })
                        }
                        setOpenConfirmDialog(false)
                    })
                }
            }
        }, [openConfirmDialog, confirmDialogType])

        switch (confirmDialogType) {
            case 'delete':
                return (
                    <Dialog
                        open={openConfirmDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{`Really want to delete this device?`}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                By clicking on "CONFIRM" button you allow to delete this device.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseDialog("cancel")} color="secondary" variant="contained">CANCEL</Button>
                            <Button onClick={() => handleCloseDialog("confirm_delete")} color="secondary" autoFocus variant="contained">CONFIRM</Button>
                        </DialogActions>
                    </Dialog>
                )
            case 'alerts':
                return (
                    <Dialog
                        open={openConfirmDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{`Really want to ${action} this alerts?`}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                By clicking on "CONFIRM" button you allow to {action} this alerts.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseDialog("cancel")} color="secondary" variant="contained">CANCEL</Button>
                            <Button onClick={() => handleCloseDialog("confirm_alerts")} color="secondary" autoFocus variant="contained">CONFIRM</Button>
                        </DialogActions>
                    </Dialog>
                )
            case 'configuration':
                return (
                    <Dialog
                        open={openConfirmDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="dialog-title"
                        aria-describedby="dialog-description"
                    >
                        <DialogTitle id="dialog-title">{`Really want to update new configuration?`}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="dialog-description">
                                By clicking on "CONFIRM" button you allow to upload new configuration for this device.
                                All the current alerts will be closed by system.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseDialog("cancel")} color="secondary" variant="contained">CANCEL</Button>
                            <Button onClick={() => handleCloseDialog("confirm_configuration")} color="secondary" autoFocus variant="contained">CONFIRM</Button>
                        </DialogActions>
                    </Dialog>
                )
            case 'nfc':
                return (
                    <Dialog
                        open={openConfirmDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="dialog-title"
                        aria-describedby="dialog-description"
                    >
                        <DialogTitle id="dialog-title">{`NFC Configuration Writer`}</DialogTitle>
                        <DialogContent>
                            {isEdited && (<DialogContentText >
                                Warning! You have unsaved changes. Please save the configuration before writing it to the NFC tag. (otherwise you will write the last saved configuration to the tag!)
                            </DialogContentText>)}
                            <DialogContentText id="dialog-description">
                                Please put the NFC tag near the device to write the configuration.
                            </DialogContentText>
                            <DialogContentText>
                                <NfcOutlined fontSize="large" style={{ fontSize: "10rem" }} />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseDialog("cancel")} color="secondary" variant="contained">CANCEL</Button>
                        </DialogActions>
                    </Dialog>
                )
            case 'battery_reset':
                return (
                    <Dialog
                        open={openConfirmDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="dialog-title"
                        aria-describedby="dialog-description"
                    >
                        <DialogTitle id="dialog-title">{`Really want to reset device battery life?`}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="dialog-description">
                                By clicking on "CONFIRM" button you reset the battery lifetime value.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseDialog("cancel")} color="secondary" variant="contained">CANCEL</Button>
                            <Button onClick={() => handleCloseDialog("confirm_reset_battery")} color="secondary" autoFocus variant="contained">CONFIRM</Button>
                        </DialogActions>
                    </Dialog>
                )
            case 'device_type':
                return (
                    <Dialog
                        open={openConfirmDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="dialog-title"
                        aria-describedby="dialog-description"
                    >
                        <DialogTitle id="dialog-title">{`Really want to reset configuration to default?`}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="dialog-description">
                                By changing the device type the configuration will be overwritten with the default configuration for the selected type.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseDialog("cancel")} color="secondary" variant="contained">CANCEL</Button>
                            <Button onClick={() => handleCloseDialog("confirm_device_type")} color="secondary" autoFocus variant="contained">CONFIRM</Button>
                        </DialogActions>
                    </Dialog>
                )
            case 'discard':
                return (
                    <Dialog
                        open={openConfirmDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="dialog-title"
                        aria-describedby="dialog-description"
                    >
                        <DialogTitle id="dialog-title">{`Really want do cancel the unsaved editing?`}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="dialog-description">
                                You are trying to close the card of a device you were editing. Confirming will cancel the changes.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseDialog("cancel")} color="secondary" variant="contained">CANCEL</Button>
                            <Button onClick={() => handleCloseDialog("confirm_discard")} color="secondary" autoFocus variant="contained">CONFIRM</Button>
                        </DialogActions>
                    </Dialog>
                )
            default:
                break
        }
    };

    return (
        <>
            <Dialog fullScreen open={openPanel} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} color="default">
                    <Toolbar>
                        <Typography variant="subtitle1">MAC {serial_number}</Typography>
                        {props.active === 'Device' ? (<></>) : (
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} className="selectionPath" maxItems={small ? 2 : 5} aria-label="breadcrumb">
                                {navbarSelectionPath}
                            </Breadcrumbs>
                        )}
                        <Tabs
                            value={selectedTab}
                            onChange={handleChangeTabs}
                            variant="fullWidth"
                            indicatorColor="secondary"
                            textColor="secondary"
                            aria-label="Tabs"
                        >
                            <LinkTab icon={<SettingsIcon />} tabIndex={0} label="Data" {...a11yProps(0)} />
                            <LinkTab icon={<DataUsageIcon />} tabIndex={1} label="Configuration" {...a11yProps(1)} />
                        </Tabs>
                        <IconButton color="inherit" onClick={handleClose} tabIndex={2} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={classes.root}>
                    <TabPanel value={selectedTab} index={0}>
                        <Typography variant="h6" className={classes.title}>DEVICE DETAILS</Typography>
                        <Grid container>
                            <Grid item xl={1} lg={2} md={2} sm={4} xs={8} className={clsx(classes.gridField)}>
                                <TextField
                                    id="txtIpAddress_show"
                                    label="Server IP"
                                    className={clsx(classes.formCtrl)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={true}
                                    variant="outlined"
                                    value={server_ip_addr}
                                    tabIndex={6}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xl={1} lg={2} md={1} sm={2} xs={4} className={clsx(classes.gridField)}>
                                <TextField
                                    id="txtServerPort_show"
                                    label="Server Port"
                                    className={clsx(classes.formCtrl)}
                                    variant="outlined"
                                    inputMode="numeric"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={true}
                                    value={server_port}
                                    tabIndex={7}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xl={2} lg={2} md={4} sm={6} xs={7} className={clsx(classes.gridField)}>
                                <TextField
                                    select
                                    label="Device Type"
                                    id="selectDeviceType_show"
                                    className={clsx(classes.formCtrl)}
                                    value={type.toLowerCase()}
                                    variant="outlined"
                                    readOnly={true}
                                    disabled={true}
                                    tabIndex={8}
                                    fullWidth
                                >
                                    {getDeviceList().map(device => {
                                        const { label, icon } = getRenderPropsFromDeviceType(device)
                                        return <MenuItem key={device} value={device}>{icon} {label}</MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xl={1} lg={2} md={3} sm={4} xs={5} className={clsx(classes.gridField)}>
                                <TextField
                                    select
                                    label="Operative Status"
                                    id="selectOperativeStatus_show"
                                    className={clsx(classes.formCtrl)}
                                    value={operative_status}
                                    readOnly={true}
                                    disabled={true}
                                    variant="outlined"
                                    tabIndex={9}
                                    fullWidth
                                >
                                    <MenuItem value={'Operational'}>Operational</MenuItem>
                                    <MenuItem value={'Maintenance'}>Maintenance</MenuItem>
                                    <MenuItem value={'Off'}>Off</MenuItem>
                                    <MenuItem value={'NonConfigurato'}>Non Configurato</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xl={1} lg={2} md={2} sm={4} xs={6} className={clsx(classes.gridField)}>
                                <TextField
                                    select
                                    tabIndex={10}
                                    variant="outlined"
                                    id="txtHeartBeatPeriod_show"
                                    name="txtHeartBeatPeriod_show"
                                    className={clsx(classes.formCtrl)}
                                    label="Heartbeat Period (Minutes)"
                                    type="number"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={true}
                                    value={heartBeatPeriod}
                                    fullWidth
                                >
                                    {getHeartbeatPeriodList(samplePeriod).map((sp, i) => {
                                        return <MenuItem key={i} value={sp}>{sp >= 60 ? `${sp / 60} h` : `${sp} min`}</MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xl={1} lg={2} md={2} sm={4} xs={6} className={clsx(classes.gridField)}>
                                <TextField
                                    select
                                    tabIndex={11}
                                    variant="outlined"
                                    id="txtSamplePeriod_show"
                                    name="txtSamplePeriod_show"
                                    label="Sample Period (Minutes)"
                                    type="number"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={true}
                                    value={samplePeriod}
                                    fullWidth
                                    className={clsx(classes.formCtrl)}
                                >
                                    {getSamplePeriodList().map((sp, i) => {
                                        return <MenuItem key={i} value={sp}>{sp >= 60 ? `${sp / 60} h` : `${sp} min`}</MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xl={2} lg={3} md={5} sm={6} xs={12} className={clsx(classes.gridField)}>
                                <TextField
                                    id="txtDescription_show"
                                    label="Description"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={true}
                                    type="text"
                                    variant="outlined"
                                    value={description === '' ? ' ' : description}
                                    tabIndex={12}
                                    fullWidth
                                    className={clsx(classes.formCtrl)}
                                />
                            </Grid>
                            <Grid item xl={1} lg={3} md={5} sm={6} xs={12} className={clsx(classes.gridField)}>
                                <TextField
                                    id="txtPositioning_show"
                                    label="Positioning Info"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={true}
                                    type="text"
                                    variant="outlined"
                                    value={positioning === '' ? ' ' : positioning}
                                    tabIndex={13}
                                    fullWidth
                                    className={clsx(classes.formCtrl)}
                                />
                            </Grid>
                            <Grid item xl={1} lg={3} md={6} sm={6} xs={6} className={clsx(classes.gridField)}>
                                <TextField
                                    id="txtText1_show"
                                    label="Text 1"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={true}
                                    type="text"
                                    variant="outlined"
                                    value={text1 === '' ? ' ' : text1}
                                    tabIndex={14}
                                    fullWidth
                                    className={clsx(classes.formCtrl)}
                                />
                            </Grid>
                            <Grid item xl={1} lg={3} md={6} sm={6} xs={6} className={clsx(classes.gridField)}>
                                <TextField
                                    id="txtText2_show"
                                    label="Text 2"
                                    type="text"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled={true}
                                    variant="outlined"
                                    value={text2 === '' ? ' ' : text2}
                                    tabIndex={15}
                                    fullWidth
                                    className={clsx(classes.formCtrl)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justify="space-between" alignItems="stretch">
                            <Grid item xs={6} lg={3} xl={2}>
                                <Paper className={classes.dualPaperTop_big}>
                                    <BatteryGauge
                                        percentage={Number(getPercentValue("battery_level", currentMeasures.battery_level)).toFixed(2)}
                                        value={`${convertToHumanReadable("battery_level", currentMeasures.battery_level).toFixed(2)} V`}
                                        height={7}
                                        border={1}
                                        labelSize={21}
                                        labelColor={'rgb(255, 255, 255)'}
                                    />
                                </Paper>
                                <Paper className={classes.dualPaperBottom_big}>
                                    <RenderUMTS value={currentMeasures.antenna_signal} />
                                </Paper>
                            </Grid>
                            <Grid item xs={6} lg={3} xl={2}>
                                <Paper className={classes.dualPaperTop_big}>
                                    <Typography variant="h6" className={classes.title}>Activated On </Typography>
                                    <Typography variant="subtitle1">{activationTs ? GetRealTimeStamp(activationTs).toLocaleString() : 'N/a'}</Typography>
                                </Paper>
                                <Paper className={classes.dualPaperBottom_big}>
                                    <Typography variant="h6" className={classes.title}>Estimated Life Time </Typography>
                                    <Typography variant="subtitle1">{remainingDays}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={6} lg={3} xl={2}>
                                <Paper className={classes.dualPaperTop_big}>
                                    <Typography variant="h6" className={classes.title}>Current Measure </Typography>
                                    <Typography variant="subtitle1">{currentMeasures.ts ? GetRealTimeStamp(currentMeasures.ts).toLocaleString() : 'N/a'}</Typography>
                                </Paper>
                                <Paper className={classes.dualPaperBottom_big}>
                                    <Typography variant="h6" className={clsx(classes.title, noHeartbeat ? classes.red : '')}>Next Measure </Typography>
                                    <Typography variant="subtitle1">{currentMeasures.ts && heartBeatPeriod ? GetRealTimeStamp(currentMeasures.ts + (heartBeatPeriod * 60)).toLocaleString() : 'N/a'}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={6} lg={3} xl={2}>
                                <Paper className={classes.paper}>
                                    <Typography variant="h6" className={classes.title}>Temperature </Typography>
                                    <GaugeChart id="temperature"
                                        style={chartStyle}
                                        nrOfLevels={420}
                                        arcsLength={gaugeProps && gaugeProps['temperature'] ? gaugeProps['temperature'].arcsLength : [3.3, 3.3, 3.3]}
                                        arcPadding={0.02}
                                        colors={gaugeProps && gaugeProps['temperature'] ? gaugeProps['temperature'].colors : [grey[500], grey[600], grey[700]]}
                                        percent={Number(getPercentValue("temperature", currentMeasures.temperature, true)).toFixed(2)}
                                        formatTextValue={value => `${convertToHumanReadable("temperature", currentMeasures.temperature).toFixed(1)} °C`}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={6} lg={3} xl={2}>
                                <Paper className={classes.paper}>
                                    <Typography variant="h6" className={classes.title}>Atm. Pressure </Typography>
                                    <GaugeChart id="pres_atm"
                                        style={chartStyle}
                                        nrOfLevels={6}
                                        arcPadding={0.02}
                                        arcsLength={gaugeProps && gaugeProps['pres_atm'] ? gaugeProps['pres_atm'].arcsLength : [3.3, 3.3, 3.3]}
                                        colors={gaugeProps && gaugeProps['pres_atm'] ? gaugeProps['pres_atm'].colors : [grey[500], grey[600], grey[700]]}
                                        percent={Number(getPercentValue("pres_atm", currentMeasures.pres_atm, true)).toFixed(2)}
                                        formatTextValue={value => deviceConfig && deviceConfig.pres_atm && deviceConfig.pres_atm.active !== 'no' ? `${convertToHumanReadable("pres_atm", currentMeasures.pres_atm).toFixed(0)} mBar` : 'N/a'}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={6} lg={3} xl={2}>
                                <Paper className={classes.paper}>
                                    <Typography variant="h6" className={classes.title}>Humidity </Typography>
                                    <GaugeChart id="humidity"
                                        style={chartStyle}
                                        nrOfLevels={6}
                                        arcPadding={0.02}
                                        arcsLength={gaugeProps && gaugeProps['humidity'] ? gaugeProps['humidity'].arcsLength : [3.3, 3.3, 3.3]}
                                        colors={gaugeProps && gaugeProps['humidity'] ? gaugeProps['humidity'].colors : [grey[500], grey[600], grey[700]]}
                                        percent={Number(getPercentValue("humidity", currentMeasures.humidity, true)).toFixed(2)}
                                        formatTextValue={value => deviceConfig && deviceConfig.humidity && deviceConfig.humidity.active !== 'no' ? `${value.toFixed(0)}%` : 'N/a'}
                                    />
                                </Paper>
                            </Grid>

                            <Grid item xs={6} lg={3} xl={2}>
                                <Paper className={classes.paper}>
                                    <Typography noWrap variant="h6" className={classes.title}>{deviceConfig && deviceConfig.analogic_sensor_1 && deviceConfig.analogic_sensor_1.active !== 'no' ? deviceConfig.analogic_sensor_1.description : 'Analog. 1'}</Typography>
                                    <GaugeChart id="analogic_sensor_1"
                                        style={chartStyle}
                                        nrOfLevels={8}
                                        arcPadding={0.02}
                                        arcsLength={gaugeProps && gaugeProps['analogic_sensor_1'] ? gaugeProps['analogic_sensor_1'].arcsLength : [3.3, 3.3, 3.3]}
                                        colors={gaugeProps && gaugeProps['analogic_sensor_1'] ? gaugeProps['analogic_sensor_1'].colors : [grey[500], grey[600], grey[700]]}
                                        percent={Number(getPercentValue("analogic_sensor", currentMeasures.analogic_sensor_1, true)).toFixed(2)}
                                        formatTextValue={value => deviceConfig && deviceConfig.analogic_sensor_1 && deviceConfig.analogic_sensor_1.active !== 'no' && typeof deviceConfig.analogic_sensor_1.ref_min === 'number' && typeof deviceConfig.analogic_sensor_1.ref_max === 'number' ? `${convertToHumanReadable_analogic("analogic_sensor_1", currentMeasures.analogic_sensor_1, deviceConfig.analogic_sensor_1.ref_min, deviceConfig.analogic_sensor_1.ref_max).toFixed(2)} ${deviceConfig.analogic_sensor_1.ref_uom}` : 'N/a'}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={6} lg={3} xl={2}>
                                <Paper className={classes.paper}>
                                    <Typography noWrap variant="h6" className={classes.title}>{deviceConfig && deviceConfig.analogic_sensor_2 && deviceConfig.analogic_sensor_2.active !== 'no' ? deviceConfig.analogic_sensor_2.description : 'Analog. 2'}</Typography>
                                    <GaugeChart id="analogic_sensor_2"
                                        style={chartStyle}
                                        nrOfLevels={8}
                                        arcPadding={0.02}
                                        arcsLength={gaugeProps && gaugeProps['analogic_sensor_2'] ? gaugeProps['analogic_sensor_2'].arcsLength : [3.3, 3.3, 3.3]}
                                        colors={gaugeProps && gaugeProps['analogic_sensor_2'] ? gaugeProps['analogic_sensor_2'].colors : [grey[500], grey[600], grey[700]]}
                                        percent={Number(getPercentValue("analogic_sensor", currentMeasures.analogic_sensor_2, true)).toFixed(2)}
                                        formatTextValue={value => deviceConfig && deviceConfig.analogic_sensor_2 && deviceConfig.analogic_sensor_2.active !== 'no' && typeof deviceConfig.analogic_sensor_2.ref_min === 'number' && typeof deviceConfig.analogic_sensor_2.ref_max === 'number' ? `${convertToHumanReadable_analogic("analogic_sensor_2", currentMeasures.analogic_sensor_2, deviceConfig.analogic_sensor_2.ref_min, deviceConfig.analogic_sensor_2.ref_max).toFixed(2)} ${deviceConfig.analogic_sensor_2.ref_uom}` : 'N/a'}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={6} lg={3} xl={2}>
                                <Paper className={classes.paper}>
                                    <Typography noWrap variant="h6" className={classes.title}>{deviceConfig && deviceConfig.analogic_sensor_3 && deviceConfig.analogic_sensor_3.active !== 'no' ? deviceConfig.analogic_sensor_3.description : 'Analog. 3'}</Typography>
                                    <GaugeChart id="analogic_sensor_3"
                                        style={chartStyle}
                                        nrOfLevels={8}
                                        arcPadding={0.02}
                                        arcsLength={gaugeProps && gaugeProps['analogic_sensor_3'] ? gaugeProps['analogic_sensor_3'].arcsLength : [3.3, 3.3, 3.3]}
                                        colors={gaugeProps && gaugeProps['analogic_sensor_3'] ? gaugeProps['analogic_sensor_3'].colors : [grey[500], grey[600], grey[700]]}
                                        percent={Number(getPercentValue("analogic_sensor", currentMeasures.analogic_sensor_3, true)).toFixed(2)}
                                        formatTextValue={value => deviceConfig && deviceConfig.analogic_sensor_3 && deviceConfig.analogic_sensor_3.active !== 'no' && typeof deviceConfig.analogic_sensor_3.ref_min === 'number' && typeof deviceConfig.analogic_sensor_3.ref_max === 'number' ? `${convertToHumanReadable_analogic("analogic_sensor_3", currentMeasures.analogic_sensor_3, deviceConfig.analogic_sensor_3.ref_min, deviceConfig.analogic_sensor_3.ref_max).toFixed(2)} ${deviceConfig.analogic_sensor_3.ref_uom}` : 'N/a'}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={6} lg={3} xl={2}>
                                <Paper className={classes.paper}>
                                    <Typography noWrap variant="h6" className={classes.title}>{deviceConfig && deviceConfig.analogic_sensor_4 && deviceConfig.analogic_sensor_4.active !== 'no' ? deviceConfig.analogic_sensor_4.description : 'Analog. 4'}</Typography>
                                    <GaugeChart id="analogic_sensor_4"
                                        style={chartStyle}
                                        nrOfLevels={8}
                                        arcPadding={0.02}
                                        arcsLength={gaugeProps && gaugeProps['analogic_sensor_4'] ? gaugeProps['analogic_sensor_4'].arcsLength : [3.3, 3.3, 3.3]}
                                        colors={gaugeProps && gaugeProps['analogic_sensor_4'] ? gaugeProps['analogic_sensor_4'].colors : [grey[500], grey[600], grey[700]]}
                                        percent={Number(getPercentValue("analogic_sensor", currentMeasures.analogic_sensor_4, true)).toFixed(4)}
                                        formatTextValue={value => deviceConfig && deviceConfig.analogic_sensor_4 && deviceConfig.analogic_sensor_4.active !== 'no' && typeof deviceConfig.analogic_sensor_4.ref_min === 'number' && typeof deviceConfig.analogic_sensor_4.ref_max === 'number' ? `${convertToHumanReadable_analogic("analogic_sensor_4", currentMeasures.analogic_sensor_4, deviceConfig.analogic_sensor_4.ref_min, deviceConfig.analogic_sensor_4.ref_max).toFixed(2)} ${deviceConfig.analogic_sensor_4.ref_uom}` : 'N/a'}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={6} lg={3} xl={2}>
                                <Paper className={classes.dualPaperTop}>
                                    <Typography variant="h6" className={classes.title}>Distance <SettingsEthernet /></Typography>
                                    {deviceConfig && deviceConfig.distance && deviceConfig.distance.active !== 'no' ?
                                        <RenderDistanceValue value={currentMeasures.distance | 0} /> : <span>Disabled</span>}
                                </Paper>
                                <Paper className={classes.dualPaperBottom}>
                                    <Typography variant="h6" className={classes.title}>Inclination <Loop /></Typography>
                                    {deviceConfig && deviceConfig.verticality && deviceConfig.verticality.active !== 'no' ?
                                        <CountUp redraw preserveValue delay={0.5} duration={3} end={currentMeasures.verticality | 0} formattingFn={(n) => { return `${n}°` }} /> : <span>Disabled</span>}
                                </Paper>
                            </Grid>
                            <Grid item xs={6} lg={3} xl={2}>
                                <Paper className={classes.dualPaperTop}>
                                    <Typography variant="h6" className={classes.title}>Door status <Loop /></Typography>
                                    {deviceConfig && deviceConfig.closed_door && deviceConfig.closed_door.active !== 'no' ? <>
                                        <FormControlLabel
                                            checked={currentMeasures.closed_door || false}
                                            control={<Switch color="primary" readOnly={true} />}
                                            label={currentMeasures.closed_door ? "Closed" : "Open"}
                                            labelPlacement="end"
                                        />
                                        <MeetingRoom />
                                    </> : deviceConfig && deviceConfig.comb_door && deviceConfig.comb_door.active !== 'no' ? 
                                        currentMeasures.comb_door === 2 ? "CLOSED" : currentMeasures.comb_door === 4 ? "FLOATING" : "OPEN" : <span>Disabled</span>}
                                </Paper>
                                <Paper className={classes.dualPaperBottom}>
                                    <Typography variant="h6" className={classes.title}>Open/close Cycles <MeetingRoom /></Typography>
                                    {deviceConfig && (deviceConfig.closed_door && deviceConfig.closed_door.active !== 'no') ||
                                        (deviceConfig.comb_door && deviceConfig.comb_door.active !== 'no') ?
                                        <CountUp redraw preserveValue delay={0.5} duration={3} end={currentMeasures.open_close_cycles | 0} /> : <span>Disabled</span>}
                                </Paper>
                            </Grid>

                            <Grid item container spacing={2} xs={12} justify="space-around" alignItems="center">
                                <Grid item xs={4} lg={1} xl={1}>
                                    <Paper className={classes.paper}>
                                        <Typography variant="h6" className={classes.title}>Digital 1</Typography>
                                        {deviceConfig && deviceConfig.digital_sensor_1 && deviceConfig.digital_sensor_1.active !== 'no' ?
                                            <FormControlLabel
                                                checked={currentMeasures.digital_sensor_1 || false}
                                                control={<Switch color="primary" readOnly={true} />}
                                                label=""
                                                labelPlacement="end"
                                            /> : <span>Disabled</span>}
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} lg={1} xl={1}>
                                    <Paper className={classes.paper}>
                                        <Typography variant="h6" className={classes.title}>Digital 2</Typography>
                                        {deviceConfig && deviceConfig.digital_sensor_2 && deviceConfig.digital_sensor_2.active !== 'no' ?
                                            <FormControlLabel
                                                checked={currentMeasures.digital_sensor_2 || false}
                                                control={<Switch color="primary" readOnly={true} />}
                                                label=""
                                                labelPlacement="end"
                                            /> : <span>Disabled</span>}
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} lg={1} xl={1}>
                                    <Paper className={classes.paper}>
                                        <Typography variant="h6" className={classes.title}>Digital 3</Typography>
                                        {deviceConfig && deviceConfig.digital_sensor_3 && deviceConfig.digital_sensor_3.active !== 'no' ?
                                            <FormControlLabel
                                                checked={currentMeasures.digital_sensor_3 || false}
                                                control={<Switch color="primary" readOnly={true} />}
                                                label=""
                                                labelPlacement="end"
                                            /> : <span>Disabled</span>}
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} lg={1} xl={1}>
                                    <Paper className={classes.paper}>
                                        <Typography variant="h6" className={classes.title}>Digital 4</Typography>
                                        {deviceConfig && deviceConfig.digital_sensor_4 && deviceConfig.digital_sensor_4.active !== 'no' ?
                                            <FormControlLabel
                                                checked={currentMeasures.digital_sensor_4 || false}
                                                control={<Switch color="primary" readOnly={true} />}
                                                label=""
                                                labelPlacement="end"
                                            /> : <span>Disabled</span>}
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} lg={1} xl={1}>
                                    <Paper className={classes.paper}>
                                        <Typography variant="h6" className={classes.title}>Connection Time Counter</Typography>
                                        <CountUp redraw preserveValue delay={0.5} duration={3} end={currentMeasures.time_spent_in_sending | 0} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justify="space-around" alignItems="center">
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Grid container direction="row" justify="space-between" alignItems="center">
                                        <Grid item xs={action === 'disabled' ? 11 : 11}>
                                            <Typography variant="h6" className={classes.title}>CURRENT ALERTS </Typography>
                                        </Grid>
                                        {action === 'disabled' ? undefined : (
                                            <Grid item xs={1}>
                                                <Button tabIndex={16} variant="contained" color="secondary" onClick={() => { setOpenConfirmDialog(true); setConfirmDialogType('alerts') }}>{action.toUpperCase()}</Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <div style={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                            sortModel={sortModel_alerts}
                                            className={classes.root}
                                            checkboxSelection
                                            onSelectionChange={handleSelectAlerts}
                                            pageSize={6}
                                            components={{
                                                noRowsOverlay: CustomNoRowsOverlay,
                                                pagination: CustomPagination,
                                            }}
                                            columnBuffer={2}
                                            {...useData(currentAlertsRows, "alerts")}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <Paper className={classes.paper}>
                                    <Grid container direction="row" justify="space-between" alignItems="center">
                                        <Grid item xs={action === 'disabled' ? 11 : 11}>
                                            <Typography variant="h6" className={classes.title}>ALERTS HISTORY </Typography>
                                        </Grid>
                                    </Grid>
                                    <div style={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                            sortModel={sortModel_alerts}
                                            className={classes.root}
                                            disableSelectionOnClick
                                            pageSize={6}
                                            components={{
                                                noRowsOverlay: CustomNoRowsOverlay,
                                                pagination: CustomPagination,
                                            }}
                                            columnBuffer={2}
                                            {...useData(alertsHistoryRows, "alerts_history")}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <Paper className={classes.paper}>
                                    <Grid container direction="row" justify="space-between" alignItems="center">
                                        <Grid item xs={action === 'disabled' ? 11 : 11}>
                                            <Typography variant="h6" className={classes.title}>MEASURES HISTORY </Typography>
                                        </Grid>
                                    </Grid>
                                    <div style={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                            sortModel={sortModel_measures}
                                            className={classes.root}
                                            disableSelectionOnClick
                                            pageSize={6}
                                            components={{
                                                noRowsOverlay: CustomNoRowsOverlay,
                                                pagination: CustomPagination,
                                            }}
                                            columnBuffer={2}
                                            {...useData(measuresHistoryRows, "measures")}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1} className={classes.bkgrnd}>
                        <div className={clsx(classes.form, classes.btn)}>
                            {permissions.devices_management ? (
                                <>
                                    <Button tabIndex={3} variant="contained" className={classes.btn} color="secondary" onClick={() => { setOpenConfirmDialog(true); setConfirmDialogType('delete') }} endIcon={<DeleteForeverIcon />}>Delete This Device</Button>
                                    <Button tabIndex={4} variant="contained" className={classes.btn} color="primary" disabled={isEdited ? false : true} onClick={() => { setOpenConfirmDialog(true); setConfirmDialogType('configuration') }} endIcon={<SaveIcon />}>Update Device Configuration</Button>
                                    <Button tabIndex={4} variant="contained" className={classes.btn} color="primary" disabled={!Writer.nfcAvailable} onClick={() => { setOpenConfirmDialog(true); setConfirmDialogType('nfc') }} endIcon={<NfcOutlined />}>Write Config to Device (NFC)</Button>
                                    <Button tabIndex={5} variant="contained" className={classes.btn} color="primary" onClick={() => { setOpenConfirmDialog(true); setConfirmDialogType('battery_reset') }} endIcon={<BatteryChargingFullIcon />}>Reset Battery Life</Button>
                                </>
                            ) : undefined}
                        </div>
                        <Typography variant="h6" className={classes.title}>DEVICE DETAILS</Typography>
                        <Grid container>
                            <Grid item xl={1} lg={2} md={2} sm={4} xs={8} className={clsx(classes.gridField)}>
                                <TextField
                                    id="txtIpAddress"
                                    label="Server IP"
                                    className={clsx(classes.formCtrl)}
                                    error={error["txtIpAddress"]}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="filled"
                                    onChange={handleChangeTextInputs}
                                    value={server_ip_addr}
                                    tabIndex={6}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xl={1} lg={2} md={1} sm={2} xs={4} className={clsx(classes.gridField)}>
                                <TextField
                                    id="txtServerPort"
                                    label="Server Port"
                                    className={clsx(classes.formCtrl)}
                                    error={error["txtServerPort"]}
                                    inputMode="numeric"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="filled"
                                    onChange={handleChangeTextInputs}
                                    value={server_port}
                                    tabIndex={7}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xl={2} lg={2} md={4} sm={6} xs={7} className={clsx(classes.gridField)}>
                                <TextField
                                    select
                                    label="Device Type"
                                    id="selectDeviceType"
                                    className={clsx(classes.formCtrl)}
                                    value={type.toLowerCase()}
                                    onChange={handleChangeDeviceType}
                                    variant="filled"
                                    readOnly={permissions.devices_management ? false : true}
                                    tabIndex={8}
                                    fullWidth
                                >
                                    {getDeviceList().map(device => {
                                        const { label, icon } = getRenderPropsFromDeviceType(device)
                                        return <MenuItem key={device} value={device}>{icon} {label}</MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xl={1} lg={2} md={3} sm={4} xs={5} className={clsx(classes.gridField)}>
                                <TextField
                                    select
                                    label="Operative Status"
                                    id="selectOperativeStatus"
                                    className={clsx(classes.formCtrl)}
                                    value={operative_status}
                                    onChange={handleChangeOperativeStatus}
                                    InputProps={{
                                        readOnly: !permissions.devices_management || operative_status === 'NonConfigurato',
                                    }}
                                    variant="filled"
                                    tabIndex={9}
                                    fullWidth
                                >
                                    <MenuItem value={'Operational'}>Operational</MenuItem>
                                    <MenuItem value={'Maintenance'}>Maintenance</MenuItem>
                                    <MenuItem value={'Off'}>Off</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xl={1} lg={2} md={2} sm={4} xs={6} className={clsx(classes.gridField)}>
                                <TextField
                                    select
                                    label="Heartbeat Period"
                                    id="selectHeartBeatPeriod"
                                    className={clsx(classes.formCtrl)}
                                    value={heartBeatPeriod}
                                    onChange={handleChangeHeartBeatPeriod}
                                    variant="filled"
                                    readOnly={permissions.devices_management ? false : true}
                                    tabIndex={10}
                                    fullWidth
                                >
                                    {getHeartbeatPeriodList(samplePeriod).map((sp, i) => {
                                        return <MenuItem key={i} value={sp}>{sp >= 60 ? `${sp / 60} h` : `${sp} min`}</MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xl={1} lg={2} md={2} sm={4} xs={6} className={clsx(classes.gridField)}>
                                <TextField
                                    select
                                    label="Sample Period"
                                    id="selectSamplePeriod"
                                    className={clsx(classes.formCtrl)}
                                    value={samplePeriod}
                                    onChange={handleChangeSamplePeriod}
                                    variant="filled"
                                    readOnly={permissions.devices_management ? false : true}
                                    tabIndex={11}
                                    fullWidth
                                >
                                    {getSamplePeriodList().map((sp, i) => {
                                        return <MenuItem key={i} value={sp}>{sp >= 60 ? `${sp / 60} h` : `${sp} min`}</MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xl={2} lg={3} md={5} sm={6} xs={12} className={clsx(classes.gridField)}>
                                <TextField
                                    id="txtDescription"
                                    label="Description"
                                    error={error["txtDescription"]}
                                    InputProps={{
                                        readOnly: permissions.devices_management ? false : true,
                                    }}
                                    type="text"
                                    variant="filled"
                                    onChange={handleChangeTextInputs}
                                    value={description || ''}
                                    tabIndex={12}
                                    fullWidth
                                    className={clsx(classes.formCtrl)}
                                />
                            </Grid>
                            <Grid item xl={1} lg={3} md={5} sm={6} xs={12} className={clsx(classes.gridField)}>
                                <TextField
                                    id="txtPositioning"
                                    label="Positioning Info"
                                    error={error["txtPositioning"]}
                                    InputProps={{
                                        readOnly: permissions.devices_management ? false : true,
                                    }}
                                    type="text"
                                    variant="filled"
                                    onChange={handleChangeTextInputs}
                                    value={positioning || ''}
                                    tabIndex={13}
                                    fullWidth
                                    className={clsx(classes.formCtrl)}
                                />
                            </Grid>
                            <Grid item xl={1} lg={3} md={6} sm={6} xs={6} className={clsx(classes.gridField)}>
                                <TextField
                                    id="txtText1"
                                    label="Text 1"
                                    InputProps={{
                                        readOnly: permissions.devices_management ? false : true,
                                    }}
                                    type="text"
                                    variant="filled"
                                    onChange={handleChangeTextInputs}
                                    value={text1 || ''}
                                    tabIndex={14}
                                    fullWidth
                                    className={clsx(classes.formCtrl)}
                                />
                            </Grid>
                            <Grid item xl={1} lg={3} md={6} sm={6} xs={6} className={clsx(classes.gridField)}>
                                <TextField
                                    id="txtText2"
                                    label="Text 2"
                                    type="text"
                                    InputProps={{
                                        readOnly: permissions.devices_management ? false : true,
                                    }}
                                    variant="filled"
                                    onChange={handleChangeTextInputs}
                                    value={text2 || ''}
                                    tabIndex={15}
                                    fullWidth
                                    className={clsx(classes.formCtrl)}
                                />
                            </Grid>
                        </Grid>
                        <Typography variant="h6" className={classes.title}>CONFIGURATION</Typography>
                        <Grid container spacing={2} alignItems='center' justify="center">
                            <Form
                                className="editConfigJson"
                                schema={configSchema}
                                onChange={handleChangeConfig}
                                onError={console.log}
                                ObjectFieldTemplate={ObjectFieldTemplate}
                                ArrayFieldTemplate={ArrayFieldTemplate}
                                formData={editedJson} />
                        </Grid>
                    </TabPanel>
                </div>
                <ConfirmDialog></ConfirmDialog>
            </Dialog>
        </>
    );
}