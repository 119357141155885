import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsLoading,
  selectUsername,
  setDeviceDetailsPanelOpen,
  setUsername,
  setIsLoading,
  setEmail,
  setPermissions,
  setSelectedDevice,
  selectUserSite,
  setUserSite,
  setSelectedSite,
  setUserSitePath
} from '../redux/reducers/dashboardSlice'
import { MyNavBar, MyDeviceDetailPanel } from "../Components"
import { createMuiTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'fontsource-roboto';
import { TextField, Button, Paper, Grid } from "@material-ui/core";
import { SearchInTextFields } from "../BkConnect";
import { useSnackbar } from "notistack";
import "./Device.css"

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const error = {
  'txtSerialNumber': false,
  'txtText_1': false,
  'txtText_2': false
}

/**
 * The Device component
 *
 * @export
 * @return {*} 
 */
export default function Device(props) {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsLoading)
  const username = useSelector(selectUsername)
  const siteId = useSelector(selectUserSite)
  const [serialNumber, setSerialNumber] = React.useState("")
  const [text1, setText1] = React.useState("")
  const [text2, setText2] = React.useState("")
  const classes = useStyles();
  const palletType = "dark"
  const { enqueueSnackbar } = useSnackbar();
  const darkTheme = createMuiTheme({
    palette: {
      type: palletType,
    }
  });

  useEffect(() => {
    dispatch(setPermissions(props.permissions))// Salva su redux l'username
    dispatch(setEmail(props.email))// Salva su redux l'username
    dispatch(setUserSite(props.siteId))// Salva su redux l'username
    dispatch(setUserSitePath(props.sitePath))// Salva su redux l'username
    dispatch(setSelectedSite(props.siteId))// Salva su redux l'username
    dispatch(setUsername(props.username))// Salva su redux l'username
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.serialNumber !== undefined) {
      setSerialNumber(props.serialNumber);
    }
  }, [props.serialNumber]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (serialNumber !== undefined && serialNumber.length === 23) {
      search()
    }
  }, [serialNumber]) // eslint-disable-line react-hooks/exhaustive-deps

  const search = async () => {
    for (let er in error) {
      if (error[er]) {
        enqueueSnackbar('You have to fill the form as well!', { variant: 'error' });
        return
      }
    }
    let columnName = ''
    let value = ''
    if (serialNumber.length > 0 && text1.length === 0 && text2.length === 0) {
      columnName = 'serial_number'
      value = serialNumber
    } else if (serialNumber.length === 0 && text1.length > 0 && text2.length === 0) {
      columnName = 'text1'
      value = text1
    } else if (serialNumber.length === 0 && text1.length === 0 && text2.length > 0) {
      columnName = 'text2'
      value = text2
    }
    const res = await SearchInTextFields(siteId, columnName, value)
    if (typeof res === 'boolean' && res === false)
      enqueueSnackbar("You don't have permissions to see this device...", { variant: 'error' })
    else if (res.length === 1) {
      dispatch(setIsLoading(true))
      enqueueSnackbar(`Found a device! Fetching data...`, { variant: 'success' })
      dispatch(setSelectedDevice(res[0]))
      dispatch(setDeviceDetailsPanelOpen(true))
    } else if (res.length === 0) {
      enqueueSnackbar("No device found...", { variant: 'error' })
    } else {
      enqueueSnackbar("More then one device found...", { variant: 'error' })
    }
  }

  const updateForm = (e) => {
    switch (e.target.id) {
      case 'txtSerialNumber':
        if (e.target.value.replace(/\D/g, '').length <= 15) {
          setSerialNumber(e.target.value.replace(/\D/g, ''))
          if (e.target.value.length === 15 && text1.length === 0 && text2.length === 0) {
            error[e.target.id] = false
            error['txtText_1'] = false
            error['txtText_2'] = false
          }
          else {
            error[e.target.id] = true
          }
        }
        break
      case 'txtText_1':
        setText1(e.target.value)
        if (e.target.value.length > 0 && serialNumber.length === 0 && text2.length === 0) {
          error['txtSerialNumber'] = false
          error[e.target.id] = false
          error['txtText_2'] = false
        }
        else {
          error[e.target.id] = true
        }
        break
      case 'txtText_2':
        setText2(e.target.value)
        if (e.target.value.length > 0 && serialNumber.length === 0 && text1.length === 0) {
          error['txtSerialNumber'] = false
          error['txtText_1'] = false
          error[e.target.id] = false
        }
        else {
          error[e.target.id] = true
        }
        break
      default:
        break
    }
  };

  if (username !== undefined) {
    return (
      <ThemeProvider theme={darkTheme}>
        <MyNavBar id="navBar" active="Device"></MyNavBar>
        <Grid container className={classes.root} spacing={0}>
          <Grid item sm={12}>
            <Paper className={classes.paper}>
              <form className={classes.root}>
                <TextField
                  id="txtSerialNumber"
                  label="Serial Number"
                  variant="filled"
                  value={serialNumber}
                  onChange={updateForm}
                  tabIndex={0}
                  error={error['txtSerialNumber']}
                />
                <TextField
                  id="txtText_1"
                  label="Text 1"
                  variant="filled"
                  value={text1}
                  onChange={updateForm}
                  tabIndex={1}
                  error={error['txtText_1']}
                />
                <TextField
                  id="txtText_2"
                  label="Text 2"
                  variant="filled"
                  value={text2}
                  onChange={updateForm}
                  tabIndex={2}
                  error={error['txtText_2']}
                />
                <Button tabIndex={3} variant="contained" color="primary" onClick={search}>Fetch Device</Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
        <MyDeviceDetailPanel></MyDeviceDetailPanel>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </ThemeProvider>
    );
  }
  else {
    return (<></>)
  }
}